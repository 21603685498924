import React, {
	FC,
	ReactNode,
	ElementType,
	useEffect,
	useState,
	useRef,
} from "react";
import classNames from "classnames";

import styles from "./Appear.module.scss";

export interface AppearProps {
	children?: ReactNode;
	elementType?: ElementType;
	className?: string;
	fromLeft?: boolean;
	fromRight?: boolean;
	fromBelow?: boolean;
	fromAbove?: boolean;
	[prop: string]: unknown;
}

export const Appear: FC<AppearProps> = ({
	children,
	elementType: Tag = "div",
	className,
	fromLeft,
	fromRight,
	fromBelow,
	fromAbove,
	...attrs
}) => {
	if (!fromLeft && !fromRight) fromBelow = true;

	const elementRef = useRef<HTMLElement>(null);

	const [hasAppeared, setHasAppeared] = useState(false);
	const [hasMounted, setHasMounted] = useState(false);

	useEffect(() => {
		setHasMounted(true);

		const onScroll = (): void => {
			if (
				elementRef.current &&
				typeof elementRef.current.getBoundingClientRect === "function"
			) {
				const boundingRect = elementRef.current.getBoundingClientRect();
				const elementTop = boundingRect.top,
					elementBottom = boundingRect.bottom;

				if (elementTop < window.innerHeight * 0.9 && elementBottom >= 0) {
					setHasAppeared(true);
				}
			}
		};

		onScroll();

		window.addEventListener("scroll", onScroll, { passive: true });

		return (): void => window.removeEventListener("scroll", onScroll);
	}, []);

	return (
		<Tag
			ref={elementRef}
			className={classNames(
				hasMounted && styles.element,
				{ [styles.fromLeft]: fromLeft },
				{ [styles.fromRight]: fromRight },
				{ [styles.fromBelow]: fromBelow },
				{ [styles.fromAbove]: fromAbove },
				{ [styles.appear]: hasAppeared },
				className
			)}
			{...attrs}
		>
			{children}
		</Tag>
	);
};
