import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useSiteMetadata } from "../../hooks/use-site-metadata";

const defaultTitle =
	"Adeptly » Game Based Learning Platform | Create eLearning Games";

const defaultDescription =
	"Boost learning outcomes with beautiful data-driven games that engage. Adeptly is the the future of workspace training.";

export interface SEOProps {
	title?: string;
	description?: string;
}

export const SEO: FC<SEOProps> = ({ title, description }: SEOProps) => {
	const location = useLocation();

	const { siteUrl } = useSiteMetadata();

	return (
		<Helmet
			htmlAttributes={{ lang: "en-GB" }}
			titleTemplate="%s | eLearning Games | Adeptly"
			defaultTitle={defaultTitle}
			title={title}
		>
			<meta charSet="utf-8" />
			<meta httpEquiv="content-language" content="en" />
			<meta name="robots" content="index, follow" />
			<meta name="description" content={description || defaultDescription} />
			<meta
				name="google-site-verification"
				content="-zvZPZFSVAPVnMWXmj0mlLbpSYbztAZ530T6no3Ts9Y"
			/>
			<meta name="theme-color" content="#1b68ff" />
			<meta property="og:title" content={title || defaultTitle} />
			<meta
				property="og:description"
				content={description || defaultDescription}
			/>
			<meta property="og:image" content={`${siteUrl}/open-graph-image.jpg`} />
			<meta property="og:image:width" content="3000" />
			<meta property="og:image:height" content="1688" />
			<meta property="og:image:alt" content="Adeptly Logo" />
			<meta property="og:url" content={`${siteUrl}${location.pathname}`} />
			<meta property="og:locale" content="en_GB" />
			<meta property="og:type" content="website" />
			<meta name="twitter:card" content="summary_large_image" />
			<link rel="icon" href="/favicon.svg" />
			<link rel="mask-icon" href="/favicon.svg" color="#1b68ff" />
		</Helmet>
	);
};
