import { useStaticQuery, graphql } from "gatsby";
import { Site, SiteMetaData } from "../types/types";

export const useSiteMetadata = (): SiteMetaData => {
	const { site } = useStaticQuery<Site>(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						twitterUsername
					}
				}
			}
		`
	);
	return site.siteMetadata;
};
