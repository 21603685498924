import React, { ReactNode, FC } from "react";
import classNames from "classnames";

import styles from "./Container.module.scss";

export interface ContainerProps {
	children: ReactNode;
	className?: string;
	isNarrow?: boolean;
	elementType?: React.ElementType;
}

export const Container: FC<ContainerProps> = ({
	children,
	className,
	isNarrow,
	elementType: ElementType = "div",
}) => (
	<ElementType
		className={classNames(styles.container, className, {
			[styles.isNarrow]: isNarrow,
		})}
	>
		{children}
	</ElementType>
);
