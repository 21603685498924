import React, { ReactNode, FC } from "react";

import { SiteHeader } from "../SiteHeader/SiteHeader";
import { SiteFooter } from "../SiteFooter/SiteFooter";

export interface LayoutProps {
	children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => (
	<>
		<SiteHeader />
		<main>{children}</main>
		<SiteFooter />
	</>
);
