import React, { ReactNode, FC } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import styles from "./Button.module.scss";

export interface ButtonProps {
	children: ReactNode;
	to?: string;
	className?: string;
	variant?: "default" | "small" | "tiny";
	inverse?: boolean;
	[key: string]: unknown;
}

export const Button: FC<ButtonProps> = ({
	children,
	to,
	className,
	variant = "default",
	inverse = false,
	...props
}) => {
	const actualClassName = classNames(
		styles.btn,
		variant == "small" ? styles.small : undefined,
		variant == "tiny" ? styles.tiny : undefined,
		inverse && styles.inverse,
		className
	);

	if (!to)
		return (
			<button type="button" {...props} className={actualClassName}>
				{children}
			</button>
		);

	// Internal links
	if (to[0] === "/" || to[0] === "#")
		return (
			<Link to={to} {...props} className={actualClassName}>
				{children}
			</Link>
		);

	// External links
	return (
		<a
			href={to}
			rel="noopener noreferrer"
			{...props}
			className={actualClassName}
		>
			{children}
		</a>
	);
};
