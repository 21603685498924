import React, { FC, ReactNode } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import styles from "./NavLink.module.scss";

export interface NavLinkProps {
	children: ReactNode;
	to: string;
}

export const NavLink: FC<NavLinkProps> = ({ children, to }) => {
	const { pathname, hash } = useLocation();

	const toHash = to.split("#")[1],
		toPath = to.split("#")[0],
		LinkElementType = to.indexOf("http") === 0 ? "a" : Link;

	const isCurrent =
		// Would you startsWith instead of slice, but IE11 :(
		pathname.slice(0, toPath.length) === toPath &&
		(toHash ? hash === `#${toHash}` : true);

	return to[0] === "/" || to[0] === "#" ? (
		<LinkElementType
			to={to}
			className={styles.navLink}
			aria-current={isCurrent}
		>
			{children}
		</LinkElementType>
	) : (
		<a href={to} className={styles.navLink}>
			{children}
		</a>
	);
};
