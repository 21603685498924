import React, { FC, ReactNode } from "react";

import styles from "./Callout.module.scss";
import { Button } from "../Button/Button";
import { Container } from "../Container/Container";
import { Appear } from "../Appear/Appear";

export interface CalloutProps {
	headingText?: ReactNode;
	ctaText?: ReactNode;
	ctaHref?: string;
	[key: string]: unknown;
}

export const Callout: FC<CalloutProps> = ({
	headingText = <>Get started with Adeptly&nbsp;today</>,
	ctaText = "Start building",
	ctaHref = "https://my.adeptly.ai/",
	...attrs
}) => {
	return (
		<Appear
			elementType="section"
			aria-labelledby="callout"
			className={styles.wrapper}
			{...attrs}
		>
			<Container>
				<div className={styles.body}>
					<h2 id="callout" className={styles.heading}>
						{headingText}
					</h2>
					<Button to={ctaHref} className={styles.button}>
						{ctaText}
					</Button>
				</div>
			</Container>
		</Appear>
	);
};
