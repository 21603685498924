import React, { FC } from "react";
import { Link } from "gatsby";

import LogoSvg from "../../assets/logo-square.svg";

import styles from "./SiteFooter.module.scss";
import { Container } from "../Container/Container";

export const SiteFooter: FC = () => (
	<footer className={styles.footer}>
		<Container className={styles.container}>
			<Link to="/" className={styles.home} aria-label="Home">
				<LogoSvg className={styles.logo} aria-hidden="true" focusable="false" />
			</Link>

			<nav aria-labelledby="menu" className={styles.nav}>
				<h2 id="menu" className={styles.navHeading}>
					Menu
				</h2>
				<ul aria-labelledby="menu" className={styles.navList}>
					{/* <li>
						<Link to="/pricing/">Pricing</Link>
					</li> */}
					<li>
						<Link to="/resource-centre/">Resource centre</Link>
					</li>
					<li>
						<Link to="/showcase/">Showcase</Link>
					</li>
					<li>
						<a href="https://community.adeptly.ai/">Community</a>
					</li>
					<li>
						<a
							download
							href="https://adeptly-lp.cdn.prismic.io/adeptly-lp/6fd70cf9-fdd0-4edc-a85e-3a748b22eb7b_Adeptly_PrivacyPolicy.pdf"
						>
							Privacy Policy
						</a>
					</li>
				</ul>
			</nav>

			<nav aria-labelledby="social" className={styles.nav}>
				<h2 id="social" className={styles.navHeading}>
					Social
				</h2>
				<ul aria-labelledby="social" className={styles.navList}>
					<li>
						<a
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.linkedin.com/company/adeptly"
						>
							LinkedIn
						</a>
					</li>
					<li>
						<a
							rel="noopener noreferrer"
							target="_blank"
							href="https://elearningindustry.com/directory/elearning-software/adeptly"
						>
							eLearning industry
						</a>
					</li>
				</ul>
			</nav>

			<nav aria-labelledby="account" className={styles.nav}>
				<h2 id="account" className={styles.navHeading}>
					Account
				</h2>
				<ul aria-labelledby="account" className={styles.navList}>
					<li>
						<Link to="https://my.adeptly.ai/">Start building</Link>
					</li>
				</ul>
			</nav>

			<nav aria-labelledby="contact" className={styles.nav}>
				<h2 id="contact" className={styles.navHeading}>
					Contact
				</h2>
				<ul aria-labelledby="contact" className={styles.navList}>
					<li>
						<a href="mailto:info@adeptly.ai">info@adeptly.ai</a>
					</li>
				</ul>
			</nav>

			<p className={styles.copyright}>
				&copy; Copyright {new Date().getFullYear()} Adeptly AI Ltd. All rights
				reserved. Various trademarks held by their respective&nbsp;owners.
			</p>
		</Container>
	</footer>
);
