import React, { FC, useCallback, useState, useEffect } from "react";
import { Link } from "gatsby";
import throttle from "lodash.throttle";
import classNames from "classnames";

import { Container } from "../Container/Container";
import { Button } from "../Button/Button";
import { NavLink } from "./NavLink/NavLink";
import LogoSvg from "../../assets/logo.svg";

import styles from "./SiteHeader.module.scss";

export const SiteHeader: FC = () => {
	const [isMobileExpanded, setIsMobileExpanded] = useState(false),
		[hasScrolled, setHasScrolled] = useState(false);

	const mobileMenuClickHandler = useCallback(() => {
		setIsMobileExpanded((currentState) => !currentState);
	}, []);

	const scrollHandler = useCallback(
		throttle(() => {
			setHasScrolled(window.scrollY > 1);
		}, 100),
		[]
	);

	useEffect(() => {
		window.addEventListener("scroll", scrollHandler, {
			passive: true,
			capture: true,
		});
		return (): void => {
			window.removeEventListener("scroll", scrollHandler, { capture: true });
		};
	}, []);

	return (
		<header
			className={classNames(styles.header, {
				[styles.isDocked]: hasScrolled,
				[styles.isNavExpanded]: isMobileExpanded,
			})}
		>
			<Container className={styles.container}>
				<Link to="/" className={styles.home} aria-label="Home">
					<LogoSvg
						className={styles.logo}
						aria-hidden="true"
						focusable="false"
					/>
				</Link>
				<Button
					className={styles.mobileCTA}
					variant="tiny"
					to="https://my.adeptly.ai/"
				>
					Start building
				</Button>
				<button
					type="button"
					className={classNames(
						styles.mobileToggleBtn,
						"hamburger",
						"hamburger--squeeze",
						{ "is-active": isMobileExpanded }
					)}
					aria-expanded={isMobileExpanded}
					onClick={mobileMenuClickHandler}
					aria-label={isMobileExpanded ? "Close menu" : "Expand menu"}
				>
					<span className="hamburger-box">
						<span className="hamburger-inner"></span>
					</span>
				</button>
				<nav className={styles.nav} aria-label="Main site navigation">
					<ul className={styles.linkList}>
						{/* <li>
							<NavLink to="/pricing/">Pricing</NavLink>
						</li> */}
						<li>
							<NavLink to="/resource-centre/">Resource centre</NavLink>
						</li>
						<li>
							<NavLink to="/showcase/">Showcase</NavLink>
						</li>
						<li>
							<NavLink to="https://community.adeptly.ai/">Community</NavLink>
						</li>
					</ul>
					<Button
						variant={hasScrolled ? "tiny" : "small"}
						to="https://my.adeptly.ai/"
						className={styles.cta}
						inverse
					>
						Start building
					</Button>
				</nav>
			</Container>
		</header>
	);
};
